import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { v4 as uuidv4 } from 'uuid'
import Layout from '../../components/Layout'
import ModalImage from 'react-modal-image'
import CodeSnippet from '../../components/CodeSnippet'
import Progress from '../../components/Progress'

let tempDate
let date
const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
}
export default class preview extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            heroImageID: '',
            heroImageData: '',
            blogDate: '',
            author: '',
            stateImage: [],
        }
    }
    componentDidMount() {
        const script = document.createElement('script')
        script.src = 'https://static.addtoany.com/menu/page.js'
        script.async = true
        this.instance.appendChild(script)

        var url_string = window.location.href
        var url = new URL(url_string)
        var blogData = url.searchParams.get('blog')
        if (blogData) {
            //Get the entry from the URL
            const contentful = require('contentful')

            const client = contentful.createClient({
                space: process.env.CONTENTFUL_SPACE_ID,
                accessToken: process.env.CONTENTFUL_PREVIEW_TOKEN,
                host: 'preview.contentful.com',
            })

            client
                .getEntry(blogData)
                .then((entry) =>
                    this.setState({
                        ...this.state,
                        data: entry,
                        heroImageID: entry.fields.heroimage.sys.id,
                    }),
                )
                // SET HERO IMAGE AND DATE
                .then(() => {
                    tempDate = this.state.data.fields.date
                    date = new Date(tempDate)
                    // console.log(this.state.data.fields.author.sys.id)
                    client.getAsset(this.state.heroImageID).then((asset) =>
                        this.setState({
                            ...this.state,
                            heroImageData: asset,
                            blogDate: date,
                            authorID: this.state.data.fields.author.sys.id,
                        }),
                    )
                })
                // SET AUTHOR
                .then(() => {
                    let authorID = this.state.data.fields.author.sys.id
                    // console.log(authorID)
                    client.getEntry(authorID).then((asset) =>
                        this.setState({
                            ...this.state,
                            author: asset,
                        }),
                    )
                })
                // SET BLOG IMAGES IN STATE
                .then(() => {
                    this.state.data.fields.blogBody.content.map((content) => {
                        if (content.nodeType === 'embedded-asset-block') {
                            let imageID = content.data.target.sys.id
                            client.getAsset(imageID).then((asset) =>
                                this.setState({
                                    ...this.state,
                                    stateImage: [...this.state.stateImage, asset],
                                }),
                            )
                        }
                    })
                })
                .catch(console.error)
        } else {
            window.location.href = '/blog/'
        }
    }

    render() {
        const { data, heroImageData, blogDate, author, stateImage } = this.state

        const blog = data.fields
        // console.log(blog)
        const heroImage = heroImageData.fields

        const returnComplexHeading = (headingContent, headingtype, className = '') => {
            let contentfinal = []
            if (headingtype === 'h1') {
                headingContent.content.map((content) => {
                    if (content.nodeType === 'text') {
                        contentfinal.push(<h1 key={uuidv4()}>{content.value}</h1>)
                    } else if (content.nodeType === 'hyperlink') {
                        contentfinal.push(
                            <Fragment key={uuidv4()}>
                                &nbsp;
                                <h1>
                                    <a className='blog-link' href={content.data.uri}>
                                        {content.content[0].value}
                                    </a>
                                </h1>{' '}
                                &nbsp;
                            </Fragment>,
                        )
                    }
                })
                return (
                    <h1 key={uuidv4()} className={`${className} d-flex flex-wrap`}>
                        {contentfinal}
                    </h1>
                )
            } else if (headingtype === 'h2') {
                headingContent.content.map((content) => {
                    if (content.nodeType === 'text') {
                        contentfinal.push(<h2 key={uuidv4()}>{content.value}</h2>)
                    } else if (content.nodeType === 'hyperlink') {
                        contentfinal.push(
                            <Fragment key={uuidv4()}>
                                &nbsp;
                                <h2>
                                    <a className='blog-link' href={content.data.uri}>
                                        {content.content[0].value}
                                    </a>
                                </h2>{' '}
                                &nbsp;
                            </Fragment>,
                        )
                    }
                })
                return (
                    <h2 key={uuidv4()} className={`${className} d-flex flex-wrap`}>
                        {contentfinal}
                    </h2>
                )
            } else if (headingtype === 'h3') {
                headingContent.content.map((content) => {
                    if (content.nodeType === 'text') {
                        contentfinal.push(<h3 key={uuidv4()}>{content.value}</h3>)
                    } else if (content.nodeType === 'hyperlink') {
                        contentfinal.push(
                            <Fragment key={uuidv4()}>
                                &nbsp;
                                <h3>
                                    <a className='blog-link' href={content.data.uri}>
                                        {content.content[0].value}
                                    </a>
                                </h3>{' '}
                                &nbsp;
                            </Fragment>,
                        )
                    }
                })
                return (
                    <h3 key={uuidv4()} className={`${className} d-flex flex-wrap`}>
                        {contentfinal}
                    </h3>
                )
            } else if (headingtype === 'h4') {
                headingContent.content.map((content) => {
                    if (content.nodeType === 'text') {
                        contentfinal.push(<h4 key={uuidv4()}>{content.value}</h4>)
                    } else if (content.nodeType === 'hyperlink') {
                        contentfinal.push(
                            <Fragment key={uuidv4()}>
                                &nbsp;
                                <h4>
                                    <a className='blog-link' href={content.data.uri}>
                                        {content.content[0].value}
                                    </a>
                                </h4>{' '}
                                &nbsp;
                            </Fragment>,
                        )
                    }
                })
                return (
                    <h4 key={uuidv4()} className={`${className} d-flex flex-wrap`}>
                        {contentfinal}
                    </h4>
                )
            } else if (headingtype === 'h5') {
                headingContent.content.map((content) => {
                    if (content.nodeType === 'text') {
                        contentfinal.push(<h5 key={uuidv4()}>{content.value}</h5>)
                    } else if (content.nodeType === 'hyperlink') {
                        contentfinal.push(
                            <Fragment key={uuidv4()}>
                                &nbsp;
                                <h5>
                                    <a className='blog-link' href={content.data.uri}>
                                        {content.content[0].value}
                                    </a>
                                </h5>{' '}
                                &nbsp;
                            </Fragment>,
                        )
                    }
                })
                return (
                    <h5 key={uuidv4()} className={`${className} d-flex flex-wrap`}>
                        {contentfinal}
                    </h5>
                )
            } else if (headingtype === 'h6') {
                headingContent.content.map((content) => {
                    if (content.nodeType === 'text') {
                        contentfinal.push(<h6 key={uuidv4()}>{content.value}</h6>)
                    } else if (content.nodeType === 'hyperlink') {
                        contentfinal.push(
                            <Fragment key={uuidv4()}>
                                &nbsp;
                                <h6>
                                    <a className='blog-link' href={content.data.uri}>
                                        {content.content[0].value}
                                    </a>
                                </h6>{' '}
                                &nbsp;
                            </Fragment>,
                        )
                    }
                })
                return (
                    <h6 key={uuidv4()} className={`${className} d-flex flex-wrap`}>
                        {contentfinal}
                    </h6>
                )
            }
        }

        const returnHeading = (content, heading, className = '') => {
            if (heading === 'h1') {
                return (
                    <h1 key={uuidv4()} className={className}>
                        {content.content[0].value}
                    </h1>
                )
            } else if (heading === 'h2') {
                return (
                    <h2 key={uuidv4()} className={className}>
                        {content.content[0].value}
                    </h2>
                )
            } else if (heading === 'h3') {
                return (
                    <h3 key={uuidv4()} className={className}>
                        {content.content[0].value}
                    </h3>
                )
            } else if (heading === 'h4') {
                return (
                    <h4 key={uuidv4()} className={className}>
                        {content.content[0].value}
                    </h4>
                )
            } else if (heading === 'h5') {
                return (
                    <h5 key={uuidv4()} className={className}>
                        {content.content[0].value}
                    </h5>
                )
            } else if (heading === 'h6') {
                return (
                    <h6 key={uuidv4()} className={className}>
                        {content.content[0].value}
                    </h6>
                )
            }
        }

        const handleHeading = (content, headingType) => {
            // h1
            let className = ''
            if (headingType === 'h1') {
                className = 'blog-h1'
            } else if (headingType === 'h2') {
                className = 'pt-5 pb-0'
            } else if (headingType === 'h3') {
                className = 'letter-spacing-normal'
            }
            if (content.content.length === 1) {
                return returnHeading(content, headingType, className)
            } else {
                return returnComplexHeading(content, headingType, className)
            }
        }
        // console.log(heroImage)
        return (
            <Fragment>
                <Layout>
                    <Progress />
                    <Helmet>
                        <title>Lenses.io Blog</title>
                        <meta name='robots' content='noindex' />
                        <meta name='robots' content='nofollow' />
                        <meta name='twitter:text:title' content={blog && blog.seoTitle} />
                        <meta name='description' content={blog && blog.description} />
                        <meta property='og:description' content={blog && blog.description} />
                        <meta property='twitter:description' content={blog && blog.description} />

                        <meta name='keywords' content={blog && blog.keywords.keywords} />

                        <meta name='twitter:card' content='summary_large_image' />
                        <meta name='twitter:site' content='@lensesio' />
                        <meta property='og:title' content={blog && blog.seoTitle} />
                        <meta property='twitter:title' content={blog && blog.seoTitle} />
                        {heroImage && (
                            <meta property='og:image' content={'https:' + heroImage.file.url} />
                        )}
                        {heroImage && (
                            <meta
                                property='twitter:image'
                                content={'https:' + heroImage.file.url}
                            />
                        )}
                    </Helmet>

                    <div className='container-1 hero-margin '>
                        <div className='d-flex flex-wrap justify-content-center'>
                            <div className='col-lg-9 col-md-12 col-12 blog-content-parent '>
                                <h1 className='lh-2-3 text-center'>{blog && blog.title}</h1>
                                <div className='w-75 mx-auto mt-4'>
                                    <h2 className='text-center my-2 fw-400 f-20 lh-32'>
                                        {blog && blog.subtitle}
                                    </h2>
                                </div>
                                <div className='d-flex justify-content-center my-3'>
                                    <img
                                        src={author && author.fields.image.fields.file.url}
                                        className='author-photo-blog mr-2'
                                        alt={author && author.fields.name}
                                    />
                                    <div className='pl-1 f-14'>
                                        {author && author.fields.display ? (
                                            <Fragment>
                                                <div className='fw-600'>
                                                    By{' '}
                                                    <a
                                                        href={`/author/${author.fields.name
                                                            .replace(/\s+/g, '-')
                                                            .toLowerCase()}/`}>
                                                        {' '}
                                                        <span className='primary-text'>
                                                            {' '}
                                                            {author.fields.name}
                                                        </span>
                                                    </a>{' '}
                                                </div>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <div className='fw-600'>
                                                    By {author && author.fields.name}
                                                </div>
                                            </Fragment>
                                        )}
                                        {blogDate &&
                                            blogDate.toLocaleDateString('en-US', dateOptions)}
                                    </div>
                                </div>
                                <div>
                                    <img
                                        className='img-fluid'
                                        alt={heroImage && heroImage.title}
                                        src={heroImage && heroImage.file.url}
                                    />
                                </div>

                                <div className='mt-3'>
                                    {/* BLOG BODY */}
                                    {this.state.data.fields &&
                                        this.state.data.fields.blogBody.content.map((content) => {
                                            // HEADINGS
                                            if (content.nodeType === 'heading-1') {
                                                return handleHeading(content, 'h1')
                                            } else if (content.nodeType === 'heading-2') {
                                                return handleHeading(content, 'h2')
                                            } else if (content.nodeType === 'heading-3') {
                                                return handleHeading(content, 'h3')
                                            } else if (content.nodeType === 'heading-4') {
                                                return handleHeading(content, 'h4')
                                            } else if (content.nodeType === 'heading-5') {
                                                return handleHeading(content, 'h5')
                                            } else if (content.nodeType === 'heading-6') {
                                                return handleHeading(content, 'h6')
                                            }
                                            // PARAGRAPHS
                                            if (content.nodeType === 'paragraph') {
                                                let par = []
                                                content.content.map((paragraph) => {
                                                    // console.log(paragraph)
                                                    if (paragraph.nodeType === 'text') {
                                                        if (
                                                            paragraph.marks[0] &&
                                                            paragraph.marks[0].type === 'code'
                                                        ) {
                                                            // console.log(paragraph)
                                                            // par.push(
                                                            //   <CodeSnippet markdown={paragraph.value} />
                                                            // )
                                                        } else if (
                                                            // CASE ALL
                                                            paragraph.marks[0] &&
                                                            paragraph.marks[1] &&
                                                            paragraph.marks[2] &&
                                                            (paragraph.marks[0].type === 'italic' ||
                                                                paragraph.marks[0].type ===
                                                                    'underline' ||
                                                                paragraph.marks[0].type ===
                                                                    'bold') &&
                                                            (paragraph.marks[1].type === 'italic' ||
                                                                paragraph.marks[1].type ===
                                                                    'underline' ||
                                                                paragraph.marks[1].type ===
                                                                    'bold') &&
                                                            (paragraph.marks[2].type === 'italic' ||
                                                                paragraph.marks[2].type ===
                                                                    'underline' ||
                                                                paragraph.marks[2].type === 'bold')
                                                        ) {
                                                            par.push(
                                                                <b key={uuidv4()}>
                                                                    <u>
                                                                        <i> {paragraph.value}</i>
                                                                    </u>
                                                                </b>,
                                                            )
                                                        } else if (
                                                            // CASES BOLD+UNDERLINE+ITALIC COMBINED
                                                            paragraph.marks[0] &&
                                                            paragraph.marks[1] &&
                                                            (paragraph.marks[0].type === 'bold' ||
                                                                paragraph.marks[1].type === 'bold')
                                                        ) {
                                                            if (
                                                                paragraph.marks[0].type ===
                                                                    'italic' ||
                                                                paragraph.marks[1].type === 'italic'
                                                            ) {
                                                                par.push(
                                                                    <b key={uuidv4()}>
                                                                        <i> {paragraph.value}</i>
                                                                    </b>,
                                                                )
                                                            } else if (
                                                                paragraph.marks[0].type ===
                                                                    'underline' ||
                                                                paragraph.marks[1].type ===
                                                                    'underline'
                                                            ) {
                                                                par.push(
                                                                    <b key={uuidv4()}>
                                                                        <u> {paragraph.value}</u>
                                                                    </b>,
                                                                )
                                                            }
                                                            // SINGLE CASES
                                                        } else if (
                                                            // CASES UNDERLINE+ITALIC COMBINED
                                                            paragraph.marks[0] &&
                                                            paragraph.marks[1] &&
                                                            (paragraph.marks[0].type === 'italic' ||
                                                                paragraph.marks[1].type ===
                                                                    'italic')
                                                        ) {
                                                            if (
                                                                paragraph.marks[0].type ===
                                                                    'underline' ||
                                                                paragraph.marks[1].type ===
                                                                    'underline'
                                                            ) {
                                                                par.push(
                                                                    <u key={uuidv4()}>
                                                                        <i> {paragraph.value}</i>
                                                                    </u>,
                                                                )
                                                            }
                                                            // SINGLE CASES
                                                        } else if (
                                                            paragraph.marks[0] &&
                                                            paragraph.marks[0].type === 'bold'
                                                        ) {
                                                            par.push(
                                                                <b key={uuidv4()}>
                                                                    {' '}
                                                                    {paragraph.value}
                                                                </b>,
                                                            )
                                                        } else if (
                                                            paragraph.marks[0] &&
                                                            paragraph.marks[0].type === 'italic'
                                                        ) {
                                                            par.push(
                                                                <i key={uuidv4()}>
                                                                    {' '}
                                                                    {paragraph.value}
                                                                </i>,
                                                            )
                                                        } else if (
                                                            paragraph.marks[0] &&
                                                            paragraph.marks[0].type === 'underline'
                                                        ) {
                                                            par.push(
                                                                <u key={uuidv4()}>
                                                                    {' '}
                                                                    {paragraph.value}
                                                                </u>,
                                                            )
                                                        } else {
                                                            par.push(
                                                                <span key={uuidv4()}>
                                                                    {' '}
                                                                    {paragraph.value}
                                                                </span>,
                                                            )
                                                        }
                                                    } else if (paragraph.nodeType === 'hyperlink') {
                                                        if (
                                                            paragraph.data.uri.includes(
                                                                'player.vimeo.com/video',
                                                            )
                                                        ) {
                                                            par.push(
                                                                <Fragment key={uuidv4()}>
                                                                    <div className='cloud-aws-msk-video'>
                                                                        <iframe
                                                                            src={paragraph.data.uri}
                                                                            style={{
                                                                                position:
                                                                                    'absolute',
                                                                                top: '0',
                                                                                left: '0',
                                                                                width: '100%',
                                                                                height: '100%',
                                                                            }}
                                                                            frameBorder='0'
                                                                            allow='autoplay; fullscreen'
                                                                            allowFullScreen></iframe>
                                                                    </div>
                                                                </Fragment>,
                                                            )
                                                        } else if (
                                                            paragraph.data.uri.includes(
                                                                'lenses.io',
                                                            ) ||
                                                            paragraph.data.uri.includes(
                                                                'landoop.com',
                                                            )
                                                        ) {
                                                            par.push(
                                                                <a
                                                                    key={uuidv4()}
                                                                    href={paragraph.data.uri}>
                                                                    {paragraph.content[0].value}
                                                                </a>,
                                                            )
                                                        } else {
                                                            par.push(
                                                                <a
                                                                    key={uuidv4()}
                                                                    target='_blank'
                                                                    rel='noopener noreferrer'
                                                                    href={paragraph.data.uri}>
                                                                    {paragraph.content[0].value}
                                                                </a>,
                                                            )
                                                        }
                                                    }
                                                })
                                                return (
                                                    <p
                                                        key={uuidv4()}
                                                        className='blog-line-height text-justify'>
                                                        {par}
                                                    </p>
                                                )
                                                // End of paragraph
                                            } else if (
                                                content.nodeType === 'unordered-list' ||
                                                content.nodeType === 'ordered-list'
                                                // LIST ITEMS
                                            ) {
                                                let listOfAll = []
                                                content.content.map((listItem) => {
                                                    let li = []
                                                    // console.log(listItem.content[0].content)
                                                    listItem.content[0].content.map(
                                                        (listItemContent) => {
                                                            if (
                                                                listItemContent.nodeType === 'text'
                                                            ) {
                                                                li.push(listItemContent.value)
                                                            } else if (
                                                                listItemContent.nodeType ===
                                                                'hyperlink'
                                                            ) {
                                                                li.push(
                                                                    <a
                                                                        key={uuidv4()}
                                                                        href={
                                                                            listItemContent.data.uri
                                                                        }>
                                                                        {
                                                                            listItemContent
                                                                                .content[0].value
                                                                        }
                                                                    </a>,
                                                                )
                                                            }
                                                        },
                                                    )
                                                    listOfAll.push(
                                                        <li key={uuidv4()}>
                                                            <p className='mb-1'> {li}</p>
                                                        </li>,
                                                    )
                                                    // list.push(<ul>{listItem}</ul>)
                                                })
                                                // console.log(content)
                                                if (content.nodeType === 'unordered-list') {
                                                    return <ul key={uuidv4()}>{listOfAll}</ul>
                                                } else if (content.nodeType === 'ordered-list') {
                                                    return <ol key={uuidv4()}>{listOfAll}</ol>
                                                }
                                                // END OF UL/OL
                                            } else if (
                                                content.nodeType === 'embedded-asset-block'
                                                // IMAGES
                                            ) {
                                                let image = []
                                                stateImage.map((stateImageMapped) => {
                                                    if (
                                                        stateImageMapped.sys.id ===
                                                        content.data.target.sys.id
                                                    ) {
                                                        image = [
                                                            stateImageMapped.fields.title,
                                                            stateImageMapped.fields.file.url,
                                                        ]
                                                    }
                                                })
                                                return (
                                                    <ModalImage
                                                        key={uuidv4()}
                                                        small={`https:${image[1]}`}
                                                        large={`https:${image[1]}`}
                                                        className='w-80 mx-auto d-flex my-3 py-2'
                                                        alt={image[0]}
                                                    />
                                                )
                                            } else if (
                                                content.nodeType === 'embedded-entry-block'
                                            ) {
                                                if (
                                                    content.data.target.sys.contentType.sys.id ===
                                                    'video'
                                                ) {
                                                    return (
                                                        <Fragment key={uuidv4()}>
                                                            <div className='cloud-aws-msk-video'>
                                                                <iframe
                                                                    src={`https://www.youtube.com/embed/${content.data.target.fields.videoId}`}
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '0',
                                                                        left: '0',
                                                                        width: '100%',
                                                                        height: '100%',
                                                                    }}
                                                                    frameBorder='0'
                                                                    allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                                                                    allowFullScreen></iframe>
                                                            </div>
                                                        </Fragment>
                                                    )
                                                } else if (
                                                    content.data.target.sys.contentType.sys.id ===
                                                    'codeSnippet'
                                                ) {
                                                    // console.log(content.data.target.fields.code)
                                                    // console.log(content.data.target.fields.code.trim())
                                                    return (
                                                        <CodeSnippet
                                                            key={uuidv4()}
                                                            markdown={
                                                                content.data.target.fields.code
                                                            }
                                                            language={`${content.data.target.fields.language.toLowerCase()}`}
                                                            style={`${content.data.target.fields.style}`}
                                                        />
                                                    )
                                                }
                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='social-container'>
                        <div
                            ref={(el) => (this.instance = el)}
                            className='a2a_kit a2a_kit_size_32 a2a_default_style d-flex flex-column'>
                            <a className='a2a_button_whatsapp  px-1 my-1'></a>
                            <a className='a2a_button_reddit  px-1 my-1'></a>
                            <a
                                className='a2a_button_twitter a2a_counter px-1 my-1'
                                id='blogpost-ending-social-button-twitter'></a>
                            <a
                                className='a2a_button_linkedin a2a_counter px-1 my-1'
                                id='blogpost-ending-social-button-linkedin'></a>
                            <a className='a2a_button_hacker_news px-1 my-1'></a>
                        </div>
                    </div>
                </Layout>
            </Fragment>
        )
    }
}
